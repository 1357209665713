<template>
  <div id="next-stop-pronunciations">
    <div v-if="is_loading" class="inline-loader">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <v-card>
        <v-card-text align="end">
          <v-dialog v-model="isPronunciationAddVisible" max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" elevation="0" v-on="on"> Add Pronunciation </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <slot name="title">Add a new pronunciation</slot>
              </v-card-title>
              <v-card-subtitle class="pt-2 px-5">
                Single words only. For multiple words, create separate pronunciations.
              </v-card-subtitle>
              <v-card-text>
                <v-form ref="pronunciation_form" lazy-validation @submit.prevent="() => {}">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="newPronunciation.name"
                        label="Word"
                        :rules="[pronunciation_rules.required]"
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="10">
                      <v-text-field v-model="newPronunciation.value" label="IPA pronunciation"> </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn class="mb-8" fab dark small color="primary" @click="TTSpreview(newPronunciation.value)">
                        <v-icon x-large>
                          {{ icons.mdiPlayCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="isPronunciationAddVisible = false"> Cancel </v-btn>
                <v-btn
                  color="primary"
                  :disabled="pronunciation_cannotSave"
                  @click="
                    addPronunciation()
                    isPronunciationAddVisible = false
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-card flat>
            <div class="pronunciations">
              <template>
                <v-data-table
                  :headers="pronunciationsHeader"
                  :items="pronunciations"
                  :items-per-page="15"
                  :sort-by.sync="pronunciationSortBy"
                >
                  <template #[`item.name`]="{ item }">
                    <v-chip>
                      {{ item.name }}
                    </v-chip>
                  </template>

                  <template #[`item.value`]="{ item }">
                    <v-edit-dialog :return-value.sync="item.value" large persistent @save="updatePronunciation(item)">
                      <v-chip>
                        {{ item.value }}
                      </v-chip>
                      <template v-slot:input>
                        <v-text-field v-model="item.value" label="Edit" single-line autofocus></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template #[`item.remove`]="{ item }">
                    <v-btn elevation="0" fab x-small @click="IPApreview(item.value)">
                      <v-icon class="d-flex justify-center align-center" size="22" color="primary">
                        {{ icons.mdiPlayCircle }}
                      </v-icon>
                    </v-btn>

                    <v-dialog
                      ref="dialog"
                      v-model="isPronunciationDeleteConfirmationVisible[item.name]"
                      persistent
                      width="400px"
                    >
                      <!-- eslint-disable vue/no-template-shadow -->
                      <template v-slot:activator="{ on, item }">
                        <v-btn elevation="0" fab x-small v-bind="item" v-on="on">
                          <v-icon class="d-flex justify-center align-center" size="22" color="primary">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <slot name="title">Delete this pronunciation?</slot>
                        </v-card-title>
                        <v-card-text>
                          Are you sure you want to delete <strong>{{ item.name }}</strong
                          >?
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            outlined
                            @click="isPronunciationDeleteConfirmationVisible[item.name] = false"
                          >
                            No
                          </v-btn>
                          <v-btn
                            color="error"
                            @click="
                              removePronunciation(item)
                              isPronunciationDeleteConfirmationVisible[item.name] = false
                            "
                          >
                            Yes, Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
              </template>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mdiPlayCircle, mdiCloseCircleOutline } from "@mdi/js"
import { mapActions, mapState } from "vuex"

import store from "@/store"

export default {
  name: "Pronunciations",
  data() {
    return {
      pronunciationsHeader: [
        { text: "Word", value: "name" },
        { text: "Pronunciation", value: "value" },
        { text: "", value: "remove", sortable: false },
      ],
      pronunciationSortBy: "name",
      isPronunciationAddVisible: false,
      isPronunciationDeleteConfirmationVisible: [],
      newPronunciation: {
        name: "",
        value: "",
      },
      pronunciation_rules: {
        required: value => !!value || "Required.",
      },
      is_loading: true,
    }
  },
  computed: {
    ...mapState({
      pronunciations: state => state.pronunciations,
    }),
    pronunciation_cannotSave() {
      return !this.newPronunciation.name || !this.newPronunciation.value
    },
  },
  created() {
    this.is_loading = true
    store.dispatch("getPronunciations").then(() => {
      this.is_loading = false
    })
  },
  methods: {
    ...mapActions(["TTSpreview", "IPApreview"]),
    async addPronunciation() {
      const item = {
        name: this.newPronunciation.name,
        value: this.newPronunciation.value,
      }
      try {
        await store.dispatch("addPronunciation", item)
        store.commit("SET_SNACKBAR", { text: `The pronunciation for ${item.name} has been added`, color: "success" })
      } catch (error) {
        console.log("error", error)
        if (error?.response?.status === 409) {
          store.commit("SET_SNACKBAR", { text: `A pronunciation for ${item.name} already exists`, color: "error" })
        } else {
          store.commit("SET_SNACKBAR", {
            text: `The pronunciation for ${item.name} could not be added`,
            color: "error",
          })
        }
      }
    },
    async updatePronunciation(item) {
      try {
        await store.dispatch("updatePronunciation", item)
        store.commit("SET_SNACKBAR", { text: `The pronunciation for ${item.name} has been updated`, color: "success" })
      } catch (error) {
        store.commit("SET_SNACKBAR", {
          text: `The pronunciation for ${item.name} could not be updated`,
          color: "error",
        })
      }
    },
    async removePronunciation(item) {
      try {
        await store.dispatch("removePronunciation", item)
        store.commit("SET_SNACKBAR", { text: `The pronunciation for ${item.name} has been removed`, color: "success" })
      } catch (error) {
        console.log(error)
        store.commit("SET_SNACKBAR", {
          text: `The pronunciation for ${item.name} could not be removed`,
          color: "error",
        })
      }
    },
  },
  setup() {
    return {
      icons: {
        mdiPlayCircle,
        mdiCloseCircleOutline,
      },
    }
  },
}
</script>
